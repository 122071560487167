import React, { PureComponent, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import authService from './api-authorization/AuthorizeService'
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants'
import { TextField, Tooltip, LinearProgress, IconButton } from '@material-ui/core'
import { FormControl, InputLabel } from '@material-ui/core'
import { MenuItem, Select, Grid, Button, Link } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Alert from '@material-ui/lab/Alert'
import { renderMap } from '../components/shared/MapHelper'
import { getIconForShipment } from '../components/shared/MapHelper'
import DateFnsUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles'
import { CardContent } from '@material-ui/core'
import SummaryInfo from './shared/SummaryInfo'
import MaterialTable from 'material-table'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import { Paper } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import ScheduleIcon from '@material-ui/icons/Schedule'
import FlagIcon from '@material-ui/icons/Flag'
import localforage from 'localforage'
import { sortData } from '../utils/sorting'
import { getOrgViewCodeFromOrganization } from '../utils/organizations'
import { clientUserHasPermission } from '../utils/permissions'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core'
import { ThemeProvider, createTheme } from '@mui/material'
import TableViewManagementDialog from './shared/TableViewManagementDialog'
import { retrieveView, getViewCodeBySearchItem, getViewNameBySearchItem, saveViewColumns } from './shared/Views'
import TableViewCreateDialog from './shared/TableViewCreateDialog'
import { getContainerExtraData } from './shared/ShipmentsHelper'
import DialogContainer from './DialogContainer'
import { prepareCsvExport } from '../utils/exporting'
import ClientSelector from '../components/shared/ClientSelector'
import ScheduledReportsManagementDialog from './shared/ScheduledReportsManagementDialog'

const styles = theme => ({
    formControl: {
        margin: 0,
        minWidth: '170px',
        fullWidth: true,
        display: 'flex',
        wrap: 'nowrap'
    },
    link: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }
})

const mapContainerStyle = {
    height: '100%',
    width: '100%'
}

const dateFns = new DateFnsUtils()

class ShipmentsTable extends PureComponent {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
        this.state = {
            items: [],
            shipmentList: [],
            clients: [],
            clientId: null,
            organization: null,
            selectedDate: 2, // this month
            toDate: dateFns.moment().endOf('month'),
            fromDate: dateFns.moment().startOf('month'),
            minDate: dateFns.moment().startOf('month'),
            todayDate: new Date(),
            loading: false,
            error: false,
            rowsPerPage: 50,
            page: 0,
            order: 'asc',
            orderBy: 'shipmentNumber',
            resultsBox1Value: 0,
            resultsBox1Title: '',
            resultsBox2Value: 0,
            resultsBox2Title: '',
            resultsBox3Value: 0,
            resultsBox3Title: '',
            resultsBox4Value: 0,
            resultsBox4Title: '',
            resultsBox5Value: 0,
            resultsBox5Title: '',
            resultsBox6Value: 0,
            resultsBox6Title: '',
            lastCompletedMilestone: 'ALL',
            referenceType: 'ALL',
            dateView: 'ALL',
            itemReference: null,
            mode: 'ALL',
            theme: props.theme,
            searchItem: props.searchItem || 'Shipments',
            titleIcon: props.titleIcon || 'assignment_turned_in',
            key: props.itemKey || 'shipment',
            originUNLOCOCodeObject: null,
            originName: null,
            originCountryCodeObject: null,
            destinationUNLOCOCodeObject: null,
            destinationName: null,
            destinationCountryCodeObject: null,
            originUnlocoCodes: [],
            originCountryCodes: [],
            destinationUnlocoCodes: [],
            destinationCountryCodes: [],
            downloadCSVDisabled: false,
            displayAdvancedSearchProperties: false,
            searching: false,
            shipmentFilters: [],
            gridSearchTypes: [],
            itemReferenceError: false,
            itemReferenceErrorMessage: '',
            approveBookingModalOpen: false,
            bookingApproveMode: 'SEA',
            bookingsToApprove: [],
            updatingApprovalMode: false,
            orgHeaderColumns: [],
            dateFiltering: [],
            userViewId: null,
            primaryColor: '#ffffff',
            secondaryColor: '#000000',
            selectedContainer: null,
            selectedShipment: null,
            showContainerDialog: false,
            currentSummaryTile: 1,
            orderClients: [],
            showOrders: false,
            orgViewCode: null,
            showScheduledReports: false,
            newReportData: null,
            canSeeScheduledReports: false
        }
    }

    async componentDidMount() {

        this.setState({ searching: true })

        await this.getOrgViewCode()
        await this.populateConfig()
        await this.setClientsWithOrderAccess()
        await this.populateClientsData()
    }

    async getOrgViewCode() {
        const viewCode = await getOrgViewCodeFromOrganization()
        this.setState({ orgViewCode: viewCode })
    }

    async runClientDependentFunctions() {
        const referredFromDetailPage = await JSON.parse(
            localStorage.getItem('referredFromDetailPage')
        )
        this.fetchGridSearchTypes()
        this.initSearch()
        this.getOrgHeaderColumns()

        if (!referredFromDetailPage) {
            // need to do a dummy data load to get the summary tile data
            this.populateTableData(true, true)
        }

        this.setState({ searching: false })
    }

    getOrgHeaderColumns = async () => {
        const { searchItem, clientId } = this.state
        const viewCode = getViewCodeBySearchItem(searchItem)
        if (clientId) {
            const view = await retrieveView(clientId, viewCode)
            if (view) {
                this.setState({ 
                    orgHeaderColumns: view.columnDefinitionsArray, 
                    userViewId: view.id
                })
            }
        }
    }

    handleContainerNumberClick = async (rowData, containerNumber) => {
        // first need to get container data
        const { clientId } = this.state
        this.setState({ searching: true })

        const container = await getContainerExtraData(rowData, containerNumber, clientId)

        if (container) {
            this.setState({
                selectedContainer: container,
                selectedShipment: rowData
            }, () => {
                this.setState({ showContainerDialog: true })
            })
            this.setState({ searching: false })
        }
        else {
            this.setState({ searching: false })
        }
    }

    setContainerDialogState = (showDialog) => {
        this.setState({ showContainerDialog: showDialog })
    }

    getModuleBySearchItem = searchItem => {
        switch (searchItem) {
            case 'Shipments':
                return 2
            case 'Bookings':
                return 1
            case 'Customs':
                return 4
            default:
                return 2
        }
    }

    handleNewScheduledReport = () => {
        // first get report data from search params then open dialog
        const newReportData = {
            module: this.state.searchItem,
            key: this.state.key,
            clientId: this.state.clientId,
            clientName: this.state.clients.find(c => c.id === this.state.clientId).name,
            transportMode: this.state.mode,
            referenceType: this.state.referenceType,
            referenceTypeDescription: this.state.gridSearchTypes.find(gst => gst.searchType === this.state.referenceType).searchTypeDescription,
            itemReference: this.state.itemReference,
            dateView: this.state.dateView,
            selectedDate: this.state.selectedDate,
            dateRange: this.state.dateFiltering.find(df => df.id === this.state.selectedDate).label,
            fromDate: dateFns.moment.utc(this.state.fromDate),
            toDate: this.state.toDate,
            reportQueryString: this.getQueryString(true, false),
            reportModule: this.getModuleBySearchItem(this.state.searchItem),
        }
        this.setState({ newReportData: newReportData }, () => {
            this.setState({ showScheduledReports: true })
        })
    }

    async initSearch() {
        // only load search from cache if navigating back from detail page
        var referredFromDetailPage = await JSON.parse(
            localStorage.getItem('referredFromDetailPage')
        )

        if (referredFromDetailPage) {
            await this.loadAndSetFromLocalForageNoKey('shipmentFilters')
            await this.loadAndSetFromLocalStorage('resultsBox1Value')
            await this.loadAndSetFromLocalStorage('resultsBox1Title')
            await this.loadAndSetFromLocalStorage('resultsBox2Value')
            await this.loadAndSetFromLocalStorage('resultsBox2Title')
            await this.loadAndSetFromLocalStorage('resultsBox3Value')
            await this.loadAndSetFromLocalStorage('resultsBox3Title')
            await this.loadAndSetFromLocalStorage('resultsBox4Value')
            await this.loadAndSetFromLocalStorage('resultsBox4Title')
            await this.loadAndSetFromLocalStorage('resultsBox5Value')
            await this.loadAndSetFromLocalStorage('resultsBox5Title')
            await this.loadAndSetFromLocalStorage('resultsBox6Value')
            await this.loadAndSetFromLocalStorage('resultsBox6Title')
            await this.loadAndSetFromLocalForage('shipmentList')
            await this.loadAndSetFromLocalForage('items')
            await this.loadAndSetFromLocalForage('currentSummaryTile')
            // reset 'referred' value in local storage
            localStorage.setItem(
                'referredFromDetailPage',
                JSON.stringify(false)
            )
        } else {
            // clear shipmentfilters?
        }

        await this.loadAndSetFromLocalStorage('itemReference')
        await this.loadAndSetFromLocalStorage('mode')
        await this.loadAndSetFromLocalStorage('referenceType')
        await this.loadAndSetFromLocalStorage('dateView')
        await this.loadAndSetFromLocalStorage('selectedDate')
        await this.loadAndSetFromLocalStorage('fromDate')
        await this.loadAndSetFromLocalStorage('toDate')

    }

    async loadAndSetFromLocalStorage(keyName, jsonParse = false) {
        const { key } = this.state
        const localStorageKey = `${key}.${keyName}`
        const value = jsonParse
            ? await JSON.parse(localStorage.getItem(localStorageKey))
            : await localStorage.getItem(localStorageKey)

        if (value !== null) {
            if (value.toLowerCase() === 'null') {
                this.setState({ [`${keyName}`]: null })
            } else {
                this.setState({ [`${keyName}`]: value }, () => {
                    if (keyName === 'selectedDate') {
                        this.setDatesFromDateDropdown(value)
                    }
                })
            }
        }
    }

    async loadAndSetFromLocalForage(keyName) {
        const { key } = this.state
        const localForageKey = `${key}.${keyName}`
        const value = await localforage.getItem(localForageKey)
        if (value !== null) {
            this.setState({ [`${keyName}`]: value })
        }
    }

    async loadAndSetFromLocalForageNoKey(keyName) {
        const value = await localforage.getItem(keyName)
        if (value !== null) {
            this.setState({ [`${keyName}`]: value })
        }
    }

    saveShipmentFilterValuesToState = tableRef => {
        const newColumnFilters = tableRef.current.state.columns.map(column => ({
            field: column.field,
            filterValue: column.tableData.filterValue
        }))
        localforage.setItem('shipmentFilters', newColumnFilters)
        this.setState({ shipmentFilters: newColumnFilters })
    }

    validSearchParameters = () => {

        this.setState({
            itemReferenceError: false,
            itemReferenceErrorMessage: ''
        })

        // if selected date is 11 (all time) then make sure the reference isn't null and is at least 3 characters
        const { selectedDate, itemReference } = this.state

        if (parseInt(selectedDate) === 10) {
            if (itemReference === null || itemReference.length < 3) {
                this.setState({
                    itemReferenceError: true,
                    itemReferenceErrorMessage: '3 characters minimum required for \'All Time\' date search.'
                })
                return false
            }
        }

        return true
    }

    getURLForItem = itemvalue => {
        const { clientId, key } = this.state

        var initialPath = `${ApplicationPaths.Shipments}`
        if (key === 'customs') {
            initialPath = `${ApplicationPaths.Customs}`
        } else if (key === 'booking') {
            initialPath = `${ApplicationPaths.Bookings}`
        }

        return {
            pathname: initialPath + '/' + clientId + '/' + itemvalue
        }
    }

    handleSubmit = async event => {
        if (this.validSearchParameters()) {
            this.saveShipmentFilterValuesToState(this.tableRef)
            this.populateTableData(event)
        } 
    }

    handleApproveBookingModalClose = () => {    
        this.setState({ approveBookingModalOpen: false })
    }

    handleBookingApproveModeChange = event => {
        this.setState({ updatingApprovalMode: true})
        this.setState({ bookingApproveMode: event.target.value }, () => {
            this.setState({ updatingApprovalMode: false})
        })
    }

    handleApproveBookingConfirm = async () => { 
        const { bookingsToApprove, bookingApproveMode } = this.state

        if (!bookingsToApprove || bookingsToApprove.length === 0) {
            alert('No bookings selected.')
            return
        }

        this.setState({ approveBookingModalOpen: false })
        this.setState({ searching: true })

        const token = await authService.getAccessToken()
        const rawResponse = await fetch('api/shipments/ApproveBookings', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ClientId: this.state.clientId,
                BookingNumbers: bookingsToApprove.map(booking => booking.shipmentNumber),
                TransportMode: bookingApproveMode,
                Consignee: bookingsToApprove[0].consignee,
                Destination: bookingsToApprove[0].destinationPortCode
            })
        })

        this.setState({ searching: false })

        if (rawResponse.ok) {
            // indicate success to user
            alert('Booking successfully approved.')
            this.populateTableData()
        } else {
            var responseObj = await rawResponse.json()
            console.log(responseObj)
            alert(responseObj.message)
        }
    }

    handleRejectBookingConfirm = async () => {

        const { bookingsToReject } = this.state

        if (!bookingsToReject || bookingsToReject.length === 0) {
            alert('No bookings selected.')
            return
        }

        this.setState({ searching: true })

        const token = await authService.getAccessToken()
        const rawResponse = await fetch('api/shipments/RejectBookings', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ClientId: this.state.clientId,
                BookingNumbers: bookingsToReject.map(booking => booking.shipmentNumber)
            })
        })

        this.setState({ searching: false })

        if (rawResponse.ok) {
            // indicate success to user
            alert('Booking(s) successfully rejected.')
            this.populateTableData()
        } else {
            var responseObj = await rawResponse.json()
            console.log(responseObj)
            alert(responseObj.message)
        }
    }

    handleClearAll = async event => {
        for (let [key, value] of Object.entries(localStorage)) {
            if (key.indexOf(`${this.state.key}.`) !== -1) {
                localStorage.removeItem(key)
            }
        }

        localStorage.removeItem('referredFromDetailPage')

        var lfKeys = []

        await localforage
            .keys()
            .then(function(keys) {
                lfKeys = keys
            })
            .catch(function(err) {
                console.log(err)
            })

        for (let lfKey of lfKeys) {
            if (lfKey.indexOf(this.state.key + '.') !== -1) {
                await localforage
                    .removeItem(lfKey)
                    .then(function() {
                        // nothing
                    })
                    .catch(function(err) {
                        console.log(err)
                    })
            }
        }

        window.location.reload()
    }

    setDatesFromDateDropdown = value => {
        let from = null
        let to = null
        const { key, fromDate, toDate } = this.state

        switch (parseInt(value)) {
            case 0: // Today
                from = dateFns.moment().startOf('day')
                to = dateFns.moment().endOf('day')
                break
            case 1: // This Week
                from = dateFns.moment().startOf('week')
                to = dateFns.moment().endOf('week')
                break
            case 2: // This Month
                from = dateFns.moment.utc().startOf('month')
                to = dateFns.moment.utc().endOf('month')
                break
            case 3: // This Year
                from = dateFns.moment.utc().startOf('year')
                to = dateFns.moment.utc().endOf('year')
                break
            case 4: // Next Week
                let nwDate = dateFns.moment.utc(new Date()).add(1, 'week')
                from = dateFns.moment(nwDate).startOf('week')
                to = dateFns.moment(nwDate).endOf('week')
                break
            case 5: // Next Month
                let nmDate = dateFns.moment.utc(new Date()).add(1, 'month')
                from = dateFns.moment(nmDate).startOf('month')
                to = dateFns.moment(nmDate).endOf('month')
                break
            case 6: // Next Year
                let nyDate = dateFns.moment.utc(new Date()).add(1, 'year')
                from = dateFns.moment(nyDate).startOf('year')
                to = dateFns.moment(nyDate).endOf('year')
                break
            case 7: // Last Week
                let lwDate = dateFns.moment.utc(new Date()).subtract(1, 'week')
                from = dateFns.moment(lwDate).startOf('week')
                to = dateFns.moment(lwDate).endOf('week')
                break
            case 8: // Last Month
                let lmDate = dateFns.moment.utc(new Date()).subtract(1, 'month')
                from = dateFns.moment(lmDate).startOf('month')
                to = dateFns.moment(lmDate).endOf('month')
                break
            case 9: // Last Year
                let lyDate = dateFns.moment.utc(new Date()).subtract(1, 'year')
                from = dateFns.moment(lyDate).startOf('year')
                to = dateFns.moment(lyDate).endOf('year')
                break
            case 10: //All Time
                break
            case 11: //Custom
                from = dateFns.moment.utc(fromDate)
                to = dateFns.moment.utc(toDate)
                break
            default:
                break
        }

        localStorage.setItem(key + '.fromDate', from)
        localStorage.setItem(key + '.toDate', to)

        this.setState({
            selectedDate: parseInt(value),
            fromDate: from,
            toDate: to
        })
    }

    handleClientSelectChange = selectedClientId => {
        localStorage.setItem(this.state.key + '.clientId', selectedClientId)
        this.setState({ clientId: selectedClientId })
    }


    handleChangeValue = (event, elem) => {
        let value = null
        const { key } = this.state

        const customIndex = 11

        switch (elem) {
            case 'fromDate':
                this.setState({ fromDate: event, selectedDate: customIndex })
                localStorage.setItem(key + '.selectedDate', customIndex)
                localStorage.setItem(key + '.fromDate', event)
                break

            case 'toDate':
                this.setState({ toDate: event, selectedDate: customIndex })
                localStorage.setItem(key + '.selectedDate', customIndex)
                localStorage.setItem(key + '.toDate', event)
                break

            case 'selectedDate':
                value = event.target.value
                localStorage.setItem(key + '.selectedDate', value)
                this.setDatesFromDateDropdown(value)
                break

            case 'dateView':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.dateView', value)
                this.setState({
                    dateView: value
                })
                break

            case 'reference-type':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.referenceType', value)
                this.setState({
                    referenceType: value
                })
                break

            case 'client':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.clientId', value)
                this.setState({
                    clientId: value
                })
                break

            case 'mode':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.mode', value)
                this.setState({
                    mode: value
                })
                break

            case 'reference':
                value = event ? event.target.value : event
                localStorage.setItem(key + '.itemReference', value)
                this.setState({
                    itemReference: value
                })
                break

            default:
                break
        }
    }

    getDateObject = (time, substract = false) => {
        var moment = require('moment')
        const format = 'YYYY-MM-DD HH:mm:ss'
        var base = substract ? moment().substract(1, 'month') : moment()
        return {
            From: base.startOf(time).format(format),
            To: base.endOf(time).format(format)
        }
    }

    getDates = () => {
        const { selectedDate } = this.state

        if (selectedDate === 0) {
            return this.getDateObject('day')
        } else if (selectedDate === 1) {
            return this.getDateObject('week')
        } else if (selectedDate === 2) {
            return this.getDateObject('month')
        } else if (selectedDate === 3) {
            return this.getDateObject('month', true)
        } else if (selectedDate === 4) {
            return this.getDateObject('year')
        }

        return this.getDateObject('day')
    }

    renderToolTipOrderRef = orderRefValue => {
        var orderRef = ''
        var array = []
        if (orderRefValue != null) {
            array = orderRefValue.split(',')
            if (array.length <= 2) {
                orderRef = array.join()
            } else {
                orderRef = array[0]
            }
        }

        return (
            <p>
                {orderRef}
                {array.length > 2 ? (
                    <Tooltip
                        title={
                            <>
                                <h6>{array.join()}</h6>
                            </>
                        }
                        placement="right">
                        <MoreHorizIcon />
                    </Tooltip>
                ) : (
                    ''
                )}
            </p>
        )
    }

    renderToolTipAddress = delAddressValue => {
        var delAddress = ''
        var array = []
        if (delAddressValue != null) {
            array = delAddressValue.split(',')
            delAddress = array[0] 
        }
        // remove any empty elements
        array = array.filter(function(e) {
            return e !== ''
        })

        return (
            <>
                {delAddress}
                <Tooltip
                    title={
                        <>
                            <h6>{array.join(', ')}</h6>
                        </>
                    }
                    placement="right">
                    <MoreHorizIcon />
                </Tooltip>
            </>
        )
    } 

    renderNavLinkOrderRef = (clientId, shipmentData) => {
        const { showOrders } = this.state
        var orderRefs = shipmentData.orderReferenceNumber
        if (orderRefs != null) {
            return (    	
                <>
                    {orderRefs.split(',').map((refnum, index) => {
                        if (shipmentData.orders) {
                            var order = shipmentData.orders.find(
                                order => order.orderNumber === refnum.trim()
                            )
                            if (order) {
                                return (
                                    <div>
                                        { showOrders ? (
                                            <NavLink
                                                onClick={() => {
                                                    this.saveShipmentFilterValuesToState(
                                                        this.tableRef
                                                    )
                                                }}
                                                key={index}
                                                to={
                                                    '/orders/' +
                                                    clientId +
                                                    '/' +
                                                    btoa(refnum.trim()).replace(
                                                        '/',
                                                        '_'
                                                    ) +
                                                    '/' +
                                                    order.orderNumberSplit
                                                }>
                                                {refnum.trim()}
                                            </NavLink>
                                        ) : (
                                            <span>{refnum.trim()}</span>
                                        )}
                                    </div>
                                )
                            } else {
                                return <div>{refnum}</div>
                            }
                        } else {
                            return <div>{refnum}</div>
                        }
                    })}
                </>
            )
        } else {
            return <>-</>
        }
    }

    renderShipmentsTable = items => {
        const {
            organization,
            key,
            searchItem,
            clientId,
            clients,
            shipmentFilters,
            orgHeaderColumns,
            userViewId,
            primaryColor,
            themeTextColor,
            canSeeScheduledReports
        } = this.state

        const tableTheme = createTheme()
        const currentClient = clients?.find(e => e.id === clientId)
        const tableViewCode = getViewCodeBySearchItem(this.state.searchItem)
        const tableViewName = getViewNameBySearchItem(this.state.searchItem)

        let cachedHeaderKeyValue = ''
        if (searchItem === 'Customs') {
            cachedHeaderKeyValue =
                currentClient?.clientview !== 'DEFAULT'
                    ? 'customsHeaders'.concat('_', currentClient?.clientviewId)
                    : 'customsHeaders'
        } else if (searchItem === 'Bookings') {
            cachedHeaderKeyValue =
                currentClient?.clientview !== 'DEFAULT'
                    ? 'bookingHeaders'.concat('_', currentClient?.clientviewId)
                    : 'bookingHeaders'
        } else {
            cachedHeaderKeyValue =
                currentClient?.clientview !== 'DEFAULT'
                    ? 'columnHeaders'.concat('_', currentClient?.clientviewId)
                    : 'columnHeaders'
        }

        const extraCells =
            key === 'booking'
                ? [
                      {
                          title: 'Booking Date',
                          value: 'bookingDate',
                          visible: true
                      }
                  ]
                : []

        // Have to map new property as column header doesn't match field value
        const mappedItems = items.map(item => ({
            ...item,
            transportMode: item.mode ? item.mode : item.transportMode
        }))

        // Map headCells to match required properties for material-table
        function getHeaderColumns() {
            const columnHeaders =
                organization !== null && orgHeaderColumns !== null
                    ? orgHeaderColumns
                          .concat(extraCells)
                          .map(headcell => {
                              return {
                                  field: headcell.value, 
                                  title: headcell.title,
                                  type: headcell.type,
                                  sortField: headcell.sortField,
                                  hidden: headcell.visible ? false : true,
                                  cellStyle: {
                                      whiteSpace: 'nowrap'
                                  },
                                  removable: true,
                                  hiddenByColumnsButton: true,
                                  id: headcell.id,
                                  order: headcell.order,
                                  customTitle: headcell.customTitle,
                                  width: headcell.width
                              }
                          })
                    : []
            return columnHeaders
        }

        let savedColumns = getHeaderColumns()

        // Need to add the render function as can't store this in localstorage
        var columnHeaders = savedColumns.map(column => {
            return {
                field: column.field,
                title: column.title,
                type: column.type,
                sortField: column.sortField,
                customSort: (a, b) => sortData(column, a, b),
                hidden: column.hidden,
                removable: column.removable,
                cellStyle: {
                    whiteSpace: 'nowrap'
                },
                hiddenByColumnsButton: column.hiddenByColumnsButton,
                defaultFilter: getFilter(column.field),
                render: params => {
                    if (column.field === 'transportMode') {
                        return getIconForShipment(params)
                    } else if (column.field === 'shipmentNumber') {
                        return (
                            <NavLink
                                onClick={() => {
                                    this.saveShipmentFilterValuesToState(
                                        this.tableRef
                                    )
                                }}
                                to={this.getURLForItem(params.shipmentNumber)}>
                                {params.shipmentNumber}
                            </NavLink>
                        )
                    } else if (column.field === 'orderReferenceNumber') {
                        return this.renderNavLinkOrderRef(clientId, params)
                    } else if (column.field === 'orderRef') {
                        return this.renderToolTipOrderRef(params.orderRef)
                    } else if (column.field === 'deliveryAddress') { 
                        return this.renderToolTipAddress(params.deliveryAddress) 
                    } else if (column.field === 'bookingApproved') {
                        return params.bookingApproved ? 'YES' : null
                    } else if (
                        organization?.containerTrackingURL !== null &&
                        column.field === 'containerNumber' &&
                        params.containerNumber !== '' &&
                        params.containerNumber !== null
                    ) {
                        return params.containerNumber.split(',').map(c => {
                            // need to remove the container type inside the parentheses
                            var parIndex = c.indexOf('(')
                            var containerNumber = parIndex === -1 ? c : c.substring(0, parIndex-1)
                            return (
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        const newWindow = window.open(
                                            organization?.containerTrackingURL.replace(
                                                '{CONTAINERNO}',
                                                containerNumber.trim()
                                            ),
                                            '_blank',
                                            'noopener,noreferrer'
                                        )
                                        if (newWindow) newWindow.opener = null
                                    }}>
                                    {c}
                                </Button>
                            )
                        })
                    } else if (!organization?.containerTrackingURL && column.field === 'containerNumber' && params.containerNumber) {
                        return params.containerNumber.split(',').map(c => {
                            var parIndex = c.indexOf('(')
                            var containerNumber = parIndex === -1 ? c : c.substring(0, parIndex-1)
                            return (
                                c ? 
                                    containerNumber ?
                                        <>
                                            <Link
                                                style={{ cursor: 'pointer', marginRight: '5px'}}
                                                color="primary"
                                                onClick={() => {
                                                    this.handleContainerNumberClick(params, containerNumber)
                                                }}>
                                                {c}
                                            </Link>
                                        </>
                                    : c
                                : ''
                            )
                        })
                    } else if (column.field === 'shipmentFlagged') {
                        return params.shipmentFlagged ? 'YES' : null
                    } else {
                        return params[column.field]
                    }
                }
            }
        })

        let tableActions = []

        if (this.state.searchItem === 'Shipments')
        {
            tableActions.push(
                {
                    icon: () => {
                        return (
                            <IconButton style={{ backgroundColor: organization?.colorThemePrimaryRGB }}>
                                <FlagIcon />
                            </IconButton>
                        )
                    },
                    tooltip: 'Toggle Shipment Flagged',
                    onClick: (event, rowData) => {
                        this.toggleShipmentFlagged(rowData)
                    }
                }
            )
        }

        if (this.state.searchItem === 'Bookings')
        {
            tableActions.push(
                {
                    icon: () => {
                        return <Button variant='contained' color='primary'>{'Approve Bookings'}</Button>
                    },
                    tooltip: 'Approve Bookings',
                    onClick: (event, rowData) => {
                        this.approveBookings(rowData)
                    }
                },
                {
                    icon: () => {
                        return <Button variant='contained' color='primary'>{'Reject Bookings'}</Button>
                    },
                    tooltip: 'Reject Bookings',
                    onClick: (event, rowData) => {
                        this.rejectBookings(rowData)
                    }
                },
                {
                    icon: () => {
                        return <Button variant='contained' color='primary'>{'Edit/Copy Booking'}</Button>
                    },
                    tooltip: 'Edit/Copy Booking',
                    onClick: (event, rowData) => {
                        this.editCopyBooking(rowData)
                    }
                }
            )
        }

        tableActions.push(
            {
                icon: () => {
                    return (
                        <TableViewManagementDialog 
                            viewCode={tableViewCode}
                            viewName={tableViewName}
                            fontColor={themeTextColor}
                            backgroundColor={primaryColor}
                            successCallback={this.getOrgHeaderColumns}
                            clientId={clientId}
                        />
                    )
                },
                isFreeAction: true
            }
        )

        tableActions.push(
            {
                icon: () => {
                    return (
                        <TableViewCreateDialog 
                            fontColor={themeTextColor}
                            backgroundColor={primaryColor}
                        />
                    )
                },
                isFreeAction: true
            }
        )

        if (canSeeScheduledReports) {
            tableActions.push(
                {
                    icon: () => {
                        return (
                            <ScheduledReportsManagementDialog
                                viewCode={tableViewCode}
                                viewName={tableViewName}
                                fontColor={themeTextColor}
                                backgroundColor={primaryColor}
                                organization={organization}
                                clientId={clientId}
                                showDialog={this.state.showScheduledReports}
                                closeDialog={() => this.setState({ showScheduledReports: false })}
                                newReportData={this.state.newReportData}
                                reportSearchTypes={this.state.gridSearchTypes}
                                reportDateOptions={this.state.dateFiltering}
                            />
                        )
                    },
                    isFreeAction: true
                }
            )
        }

        const handleColumnDrag = (sourceIndex, destinationIndex) => {
            // take copy of source column
            const sourceColumn = savedColumns[sourceIndex]
            // remove source column
            savedColumns.splice(sourceIndex, 1)
            // insert at destination
            savedColumns.splice(destinationIndex, 0, sourceColumn)          
            // update database   
            const viewCode = getViewCodeBySearchItem(searchItem)
            // map savedColumns from frontend to match the database
            savedColumns = savedColumns.map(column => {
                return {
                    id: column.id,
                    title: column.title,
                    customTitle: column.customTitle,
                    value: column.field, 
                    width: column.width,
                    visible: column.hidden ? false : true,  
                    order: column.order,
                    type: column.type,
                    sortField: column.sortField
                }
            })

            this.setState({ orgHeaderColumns: savedColumns })

            saveViewColumns(viewCode, savedColumns, userViewId, currentClient?.clientviewId, true, clientId)
        }


        function handleChangeColumnHidden(column, hidden) {
            // Update hidden flag
            for (let i = 0; i < savedColumns.length; i++) {
                if (savedColumns[i].field === column.field) {
                    // Set column hidden
                    savedColumns[i].hidden = hidden
                    localStorage.setItem(
                        cachedHeaderKeyValue,
                        JSON.stringify(savedColumns)
                    )
                    break
                }
            }
        }

        function getFilter(field) {
            const columnData = shipmentFilters.find(col => col.field === field)
            if (columnData) {
                return columnData.filterValue
            } else {
                return null
            }
        }

        return (
            <ThemeProvider theme={tableTheme}>
                <MaterialTable
                    tableRef={this.tableRef}
                    columns={columnHeaders}
                    data={mappedItems}
                    isLoading={this.state.searching}
                    icons={{
                        SortArrow: forwardRef((props, ref) => (
                            <ArrowDownward {...props} ref={ref} />
                        ))
                    }}
                    actions={tableActions}
                    options={{
                        filterRowStyle: {
                            position: "sticky",
                            top: 49,
                            background: "white",
                            zIndex: 5 /* optionally */
                        },
                        filtering: true,
                        maxBodyHeight: 'calc(100vh - 470px)',
                        pageSizeOptions: [25, 50, 75],
                        sorting: true,
                        pageSize: 25,
                        showTitle: false,
                        search: true,
                        searchFieldAlignment: 'left',
                        columnsButton: false,
                        doubleHorizontalScroll: true,
                        draggable: true,
                        showFirstLastPageButtons: true,
                        toolbar: true,
                        tableLayout: 'auto',
                        padding: 'dense',
                        selection: key === 'booking' ? true : false,
                        pagination: {
                            rowsPerPage: 'Rows per page:',
                            displayRows: 'off'
                        },
                        exportButton: { csv: true },
                        exportAllData: true,
                        rowStyle: rowData => ({
                            // check if 'hot order'
                            backgroundColor: rowData.shipmentFlagged
                                ? '#F8AFA7'
                                : null
                        }),
                        exportCsv: (columns, data) => prepareCsvExport(columns, data, this.state.organization, searchItem + 'Export_' + Date.now())
                    }}
                    onColumnDragged={handleColumnDrag}
                    onChangeColumnHidden={handleChangeColumnHidden}
                />
            </ThemeProvider>
        )
    }

    renderSummary = () => {
        const { classes } = this.props
        const {
            resultsBox1Value,
            resultsBox1Title,
            resultsBox2Value,
            resultsBox2Title,
            resultsBox3Value,
            resultsBox3Title,
            resultsBox4Value,
            resultsBox4Title,
            resultsBox5Value,
            resultsBox5Title,
            resultsBox6Value,
            resultsBox6Title,
            key,
            currentSummaryTile
        } = this.state

        const handleSummaryTileClick = (tileName, tileId) => {
            // these may have changed
            this.saveShipmentFilterValuesToState(this.tableRef)
            var newShipmentList = this.state.items.filter(
                i => i.status !== null && i.status.includes(tileName)
            )
            this.setState({
                shipmentList: newShipmentList,
                currentSummaryTile: tileId
            })
            localforage.setItem(key + '.shipmentList', newShipmentList)
            localforage.setItem(key + '.currentSummaryTile', tileId)
        }

        return (
            <div className="theme-card root">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <SummaryInfo
                                    className="theme-mini-card gm-blue"
                                    title={resultsBox1Title}
                                    data={resultsBox1Value}
                                    isClicked={currentSummaryTile === 1}
                                    onClick={() => {
                                        this.setState({
                                            shipmentList: this.state.items,
                                            currentSummaryTile: 1
                                        })
                                        localforage.setItem(key + '.shipmentList', this.state.items)
                                        localforage.setItem(key + '.currentSummaryTile', 1)
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <SummaryInfo
                                    className="theme-mini-card gm-dark-orchid"
                                    title={resultsBox2Title}
                                    data={resultsBox2Value}
                                    isClicked={currentSummaryTile === 2}
                                    onClick={() => {
                                        handleSummaryTileClick(resultsBox2Title, 2)
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <SummaryInfo
                                    className="theme-mini-card gm-forest-green"
                                    title={resultsBox3Title}
                                    data={resultsBox3Value}
                                    isClicked={currentSummaryTile === 3}
                                    onClick={() => {
                                        handleSummaryTileClick(resultsBox3Title, 3)
                                    }}
                                />
                            </Grid>
                            {resultsBox4Title ? (
                                <Grid item xs>
                                    <SummaryInfo
                                        className="theme-mini-card gm-ruby"
                                        title={resultsBox4Title}
                                        data={resultsBox4Value}
                                        isClicked={currentSummaryTile === 4}
                                        onClick={() => {
                                            handleSummaryTileClick(resultsBox4Title, 4)
                                        }}
                                    />
                                </Grid>
                            ) : null}
                            {resultsBox5Title ? (
                                <Grid item xs>
                                    <SummaryInfo
                                        className="theme-mini-card gm-purple-heart"
                                        title={resultsBox5Title}
                                        data={resultsBox5Value}
                                        isClicked={currentSummaryTile === 5}
                                        onClick={() => {
                                            handleSummaryTileClick(resultsBox5Title, 5)
                                        }}
                                    />
                                </Grid>
                            ) : null}
                            {resultsBox6Title ? (
                                <Grid item xs>
                                    <SummaryInfo
                                        className="theme-mini-card gm-blue"
                                        title={resultsBox6Title}
                                        data={resultsBox6Value}
                                        isClicked={currentSummaryTile === 6}
                                        onClick={() => {
                                            handleSummaryTileClick(resultsBox6Title, 6)
                                        }}
                                    />
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    render() {
        const { classes } = this.props

        const {
            clients,
            clientId,
            selectedDate,
            fromDate,
            toDate,
            minDate,
            todayDate,
            referenceType,
            dateView,
            itemReference,
            mode,
            organization,
            titleIcon,
            itemReferenceError,
            itemReferenceErrorMessage,
            dateFiltering,
            primaryColor,
            themeTextColor,
            showContainerDialog,
            selectedContainer,
            selectedShipment,
            shipmentList,
            canSeeScheduledReports
        } = this.state

        const currentClient = clients?.find(e => e.id === clientId)

        let contents = this.state.loading ? (
            <LinearProgress color="primary" />
        ) : (
            <div>
                <CardContent className="p-0">
                    {/* <div className="d-flex align-items-center mb-20">
                        <h3 className="mb-0 text-black">
                            {this.state.searchItem + ' Search'}
                        </h1>
                        <Icon className="ml-2 font-40">{titleIcon}</Icon>
                    </div> */}
                    <Paper className="paper page-cover-height-200px">
                        <Grid container spacing={0} direction="column">
                            <Grid
                                item
                                xs={12}
                                className="mb-0 mb-4 w-100 d-flex">
                                <Grid item xs={3}>
                                    {this.state.error && (
                                        <Grid item xs={12}>
                                            <Alert
                                                className="mb-4"
                                                onClose={() => {
                                                    this.setState({
                                                        error: false
                                                    })
                                                }}
                                                severity="error">
                                                {' '}
                                                Sorry, there was an error while
                                                retrieving items.
                                            </Alert>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid
                                container
                                spacing={0}
                                className="mb-4 w-100 d-flex mobile-mode-no-flex">
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <div
                                        className="mr-2 mb-3 map"
                                        style={{
                                            height: mapContainerStyle.height,
                                            position: 'relative',
                                            overflow: 'hidden'
                                        }}>
                                        {renderMap(shipmentList)}
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                    className="p-25 pb-0 mt-2">
                                    <Grid
                                        container
                                        direction="row"
                                        space={2}
                                        spacing={2}
                                        className="mb-4 searchshipment-col-padding">
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20">
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="select-label-client">
                                                    Client
                                                </InputLabel>
                                                <ClientSelector
                                                    clients={clients}
                                                    selectedClientId={clientId ? clientId : clients[0]?.id}
                                                    handleClientSelection={this.handleClientSelectChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20">
                                            <FormControl className="mr-auto">
                                                <div>
                                                    <InputLabel
                                                        id="select-label-mode"
                                                        className="my-auto">
                                                        Mode
                                                    </InputLabel>
                                                    <Select
                                                        labelId="select-label-mode"
                                                        id="mode"
                                                        value={mode}
                                                        onChange={e =>
                                                            this.handleChangeValue(
                                                                e,
                                                                'mode'
                                                            )
                                                        }>
                                                        <MenuItem key={1} value={'ALL'}>
                                                            All
                                                        </MenuItem>
                                                        <MenuItem key={2} value={'AIR'}>
                                                            Air
                                                        </MenuItem>
                                                        <MenuItem key={3} value={'SEA'}>
                                                            Sea
                                                        </MenuItem>
                                                        <MenuItem key={4} value={'ROA'}>
                                                            Road
                                                        </MenuItem>
                                                        <MenuItem key={5} value={'Courier'}>
                                                            COU
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20">
                                            <FormControl fullWidth={true}>
                                                <InputLabel id="select-label-referencetype">
                                                    Search Type
                                                </InputLabel>
                                                <Select
                                                    labelId="select-label-referencetype"
                                                    id="reference-type"
                                                    value={referenceType || ''}
                                                    onChange={e =>
                                                        this.handleChangeValue(
                                                            e,
                                                            'reference-type'
                                                        )
                                                    }>
                                                    {
                                                        this.state.gridSearchTypes.map(
                                                            function(
                                                                type,
                                                                i
                                                            ) {
                                                                return (
                                                                    <MenuItem
                                                                        key={
                                                                            i+1
                                                                        }
                                                                        value={
                                                                            type.searchType
                                                                        }>
                                                                        {
                                                                            type.searchTypeDescription
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20">
                                            <FormControl
                                                className={classes.formControl}>
                                                <InputLabel id="Reference2">
                                                    Reference # (3 chars min)
                                                </InputLabel>
                                                <TextField
                                                    error={itemReferenceError}
                                                    helperText={itemReferenceErrorMessage}
                                                    size="small"
                                                    id="reference"
                                                    labelid="Reference2"
                                                    placeholder="Reference # (3 chars min)"
                                                    value={itemReference || ''}
                                                    onChange={e =>
                                                        this.handleChangeValue(
                                                            e,
                                                            'reference'
                                                        )
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        space={2}
                                        spacing={2}
                                        className="mb-4 searchshipment-col-padding  ">
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20 mt-2">
                                            <FormControl className="mx-auto">
                                                <div>
                                                    <InputLabel
                                                        className="my-auto mx-auto flex-shrink-1"
                                                        id="select-label-dateview">
                                                        Date View
                                                    </InputLabel>
                                                    <Select
                                                        labelId="select-label-dateview"
                                                        id="dateView"
                                                        value={dateView}
                                                        onChange={e =>
                                                            this.handleChangeValue(
                                                                e,
                                                                'dateView'
                                                            )
                                                        }>
                                                        {this.state
                                                            .searchItem ===
                                                        'Bookings' ? (
                                                            <MenuItem
                                                                value={
                                                                    'BOOKED DATE'
                                                                }>
                                                                Booked Date
                                                            </MenuItem>
                                                        ) : null}
                                                        {this.state
                                                            .searchItem ===
                                                        'Bookings' ? (
                                                            <MenuItem
                                                                value={
                                                                    'CREATE DATE'
                                                                }>
                                                                Created Date
                                                            </MenuItem>
                                                        ) : null}
                                                        <MenuItem value={'ETD'}>
                                                            ETD
                                                        </MenuItem>
                                                        {this.state
                                                            .searchItem !==
                                                        'Bookings' ? (
                                                            <MenuItem
                                                                value={
                                                                    'ATD'
                                                                }>
                                                                ATD
                                                            </MenuItem>
                                                        ) : null}
                                                        <MenuItem value={'ETA'}>
                                                            ETA
                                                        </MenuItem>
                                                        {this.state
                                                            .searchItem !==
                                                        'Bookings' ? (
                                                            <MenuItem
                                                                value={
                                                                    'ATA'
                                                                }>
                                                                ATA
                                                            </MenuItem>
                                                        ) : null}
                                                        {currentClient?.clientview ===
                                                        'CLARK' ? (
                                                            <MenuItem
                                                                value={
                                                                    'DISCHARGEETA'
                                                                }>
                                                                Discharge Port
                                                                ETA
                                                            </MenuItem>
                                                        ) : null}
                                                        <MenuItem value={'ALL'}>
                                                            All
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="mb-20 mt-2">
                                            <FormControl className="mx-auto">
                                                <div>
                                                    <InputLabel id="select-label-date">
                                                        Date
                                                    </InputLabel>
                                                    <Select
                                                        labelId="select-label-date"
                                                        value={selectedDate}
                                                        onChange={e =>
                                                            this.handleChangeValue(
                                                                e,
                                                                'selectedDate'
                                                            )
                                                        }>
                                                        {dateFiltering.map(
                                                            function(date, i) {
                                                                return (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={
                                                                            date.id
                                                                        }>
                                                                        {
                                                                            date.label
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="">
                                            <FormControl className="mx-auto calender-icon-controller">
                                                <div>
                                                    <MuiPickersUtilsProvider
                                                        utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk
                                                            disabled={selectedDate === 10 ? true : false}
                                                            format={organization?.pickerDateFormat}
                                                            label="From Date"
                                                            value={
                                                                fromDate
                                                                    ? fromDate
                                                                    : minDate
                                                            }
                                                            onChange={e =>
                                                                this.handleChangeValue(
                                                                    e,
                                                                    'fromDate'
                                                                )
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="">
                                            <FormControl className="mx-auto  calender-icon-controller">
                                                <div>
                                                    <MuiPickersUtilsProvider
                                                        className={
                                                            classes.textField
                                                        }
                                                        utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disabled={selectedDate === 10 ? true : false}
                                                            autoOk
                                                            format={organization?.pickerDateFormat}
                                                            label="To Date"
                                                            value={
                                                                toDate
                                                                    ? toDate
                                                                    : todayDate
                                                            }
                                                            minDate={
                                                                fromDate
                                                                    ? fromDate
                                                                    : todayDate
                                                            }
                                                            onChange={e =>
                                                                this.handleChangeValue(
                                                                    e,
                                                                    'toDate'
                                                                )
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                        className="mb-4 searchshipment-col-padding mt-2 ">
                                        <Grid
                                            item
                                            xs={canSeeScheduledReports ? 4 : 6}>
                                            <Button
                                                className="MuiButton-containedPrimary submit w-100"
                                                style={{
                                                    background: primaryColor,
                                                    color: themeTextColor
                                                }}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                disabled={this.state.searching}
                                                onClick={e =>
                                                    this.handleSubmit(true)
                                                }>
                                                {this.state.searching
                                                    ? 'Loading...'
                                                    : 'SEARCH'}
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={canSeeScheduledReports ? 4 : 6}>
                                            <Button
                                                style={{
                                                    border: primaryColor,
                                                    color: primaryColor
                                                }}
                                                size="small"
                                                className="submit mb-2 w-100 secondary"
                                                variant="contained"
                                                color="secondary"
                                                disabled={this.state.searching}
                                                onClick={this.handleClearAll}>
                                                RESET SEARCH
                                            </Button>
                                        </Grid>
                                        {
                                            canSeeScheduledReports && 
                                                <Grid
                                                    item
                                                    xs={4}>
                                                    <Button
                                                        style={{
                                                            border: primaryColor,
                                                            color: primaryColor
                                                        }}
                                                        size="small"
                                                        className="submit mb-2 w-100 secondary"
                                                        variant="contained"
                                                        color="secondary"
                                                        disabled={this.state.searching}
                                                        onClick={this.handleNewScheduledReport}
                                                        startIcon={<ScheduleIcon />}>
                                                        SCHEDULE REPORT
                                                    </Button>
                                                </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className="mb-2 w-100 d-flex align-items-center mobile-mode-no-flex">
                                <Grid item xs={12} className="px-4 w-100">
                                    {this.renderSummary()}
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} className="w-100 px-3">
                                <div className="psa-shipment-table custom-table-styles w-100">
                                    <Grid
                                        container
                                        spacing={0}
                                        className="mb-4 w-100">
                                        <Grid item xs={12}>
                                            <div className="first-row-filter-hidden">
                                                {this.renderShipmentsTable(
                                                    shipmentList
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </CardContent>
                <Dialog
                    open={this.state.approveBookingModalOpen}
                    onClose={this.handleApproveBookingModalClose}>
                    <DialogTitle>Select Booking Transport Mode</DialogTitle>
                    <DialogContent>
                        <Select
                            value={this.state.bookingApproveMode}
                            onChange={this.handleBookingApproveModeChange}
                        >
                            <MenuItem key = {1} value={'SEA'}>Sea</MenuItem>
                            <MenuItem key = {2} value={'AIR'}>Air</MenuItem>
                            <MenuItem key = {3} value={'COU'}>Courier</MenuItem>
                            <MenuItem key = {4} value={'ROA'}>Road</MenuItem>
                            <MenuItem key = {5} value={'RAI'}>Rail</MenuItem>
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleApproveBookingModalClose} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={this.state.updatingApprovalMode} onClick={this.handleApproveBookingConfirm} color="primary">
                            Approve
                        </Button>
                    </DialogActions>
                </Dialog>
                <DialogContainer
                    dialogCallback={this.setContainerDialogState}
                    showDialog={showContainerDialog}
                    container={selectedContainer}
                    organization={organization}
                    client={currentClient}
                    shipment={selectedShipment}
                />
            </div>  
        )

        return <div>{contents}</div>
    }

    async populateClientsData() {
        const token = await authService.getAccessToken()
        const { clientId, key, searchItem } = this.state
        const headers = {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }

        const response = await fetch(`api/clients/getclientslist?module=${searchItem.toLowerCase()}`, headers)

        if (response.ok) {
            const data = await response.json()
            this.setState({ clients: data }, () => {
                const { clients } = this.state
                const cachedClientId = localStorage.getItem(key + '.clientId')
                if (cachedClientId) {
                    // see if cached client id is in clients array
                    const client = clients?.find(
                        client => client.id === cachedClientId
                    )
                    if (client) {
                        this.setState({ clientId: cachedClientId }, () => {
                            this.runClientDependentFunctions()
                        })
                    } else {
                        // set client id to first client in clients array
                        this.setState({ clientId: clients[0]?.id }, () => {
                            localStorage.setItem(key + '.clientId', clients[0]?.id)
                            this.runClientDependentFunctions()
                        })
                    }
                } else {
                    // set client id to first client in clients array
                    this.setState({ clientId: clients[0]?.id }, () => {
                        localStorage.setItem(key + '.clientId', clients[0]?.id)
                        this.runClientDependentFunctions()
                    })
                }
            })
        } else {
            this.setState({ loading: false, error: true }, () => {
                return false
            })
        }
    }

    async setClientsWithOrderAccess() {
        const token = await authService.getAccessToken()
        const headers = {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }

        const response = await fetch(`api/clients/getclientslist?module=orders`, headers)

        if (response.ok) {
            const data = await response.json()
            this.setState({ orderClients: data })
        } 
    }

    async populateConfig() {
        const { key } = this.state
        const response = await fetch('api' + ApplicationPaths.AppInfo)
        const data = await response.json()
        this.setState({ 
            organization: data,
            canSeeScheduledReports: data.featureAccessParameters.find(fap => fap.featureCode === 'ScheduledReports') ? true : false,
            dateFiltering: data.dateFilterTypeArray,
            primaryColor: data.colorThemePrimaryRGB,
            themeTextColor: data.colorThemeTextRGB
        })
        localforage.setItem(key + '.organization', data)
    }

    async fetchGridSearchTypes() {
        const { clientId, key } = this.state
        const itemKeyUpper = key?.toUpperCase()

        if (clientId) {
            const token = await authService.getAccessToken()
            const response = await fetch(
                'api/DataManagement/GetGridSearchTypes?clientId=' +
                    clientId +
                    '&module=' + itemKeyUpper,
                {
                    headers: !token ? {} : { Authorization: `Bearer ${token}` }
                }
            )
            if (response.ok) {
                const data = await response.json()
                this.setState({ gridSearchTypes: data })
            }
        }
    }

    getQueryString = (useQuickSearch = true, initialLoad = false) => {
        const {
            clientId,
            referenceType,
            fromDate,
            toDate,
            mode,
            itemReference,
            originUNLOCOCodeObject,
            originCountryCodeObject,
            originName,
            destinationUNLOCOCodeObject,
            destinationCountryCodeObject,
            destinationName,
            dateView,
            searchItem,
            selectedDate
        } = this.state

        const queryString = require('query-string')

        var qs = {}

        qs['SearchItem'] = searchItem
        qs['referenceType'] = referenceType
        qs['Mode'] = mode
        qs['UseQuickSearch'] = true

        if (itemReference !== null && itemReference.length > 1) {
            qs['ShipmentSearchNumber'] = itemReference
        }

        if (originUNLOCOCodeObject !== null) {
            qs['Origin'] = originUNLOCOCodeObject.unloco
        }

        if (originCountryCodeObject !== null) {
            qs['OriginCountryName'] = originCountryCodeObject.countryName
        }

        if (originName !== null) {
            qs['OriginName'] = originName
        }

        if (destinationUNLOCOCodeObject !== null) {
            qs['Destination'] = destinationUNLOCOCodeObject.unloco
        }

        if (destinationCountryCodeObject !== null) {
            qs['DestinationCountryName'] =
                destinationCountryCodeObject.countryName
        }

        if (destinationName !== null) {
            qs['DestinationName'] = destinationName
        }

        qs['DateType'] = dateView

        if (fromDate != null) {
            qs['FromDate'] = fromDate.toISOString(false)
        }

        if (toDate != null) {
            qs['ToDate'] = toDate.toISOString(false)
        }

        if (clientId !== null) {
            qs['ClientId'] = clientId
        }

        if (initialLoad) {
            qs['InitialLoad'] = true
        }

        qs['SelectedDate'] = selectedDate

        return queryString.stringify(qs)
    }

    async approveBookings(rowData) {

        let approvecount = rowData.length
        // prompt user to confirm they want to approve booking
        if (approvecount > 1) {
            if (
                !window.confirm(
                    `Are you sure you want to approve and group ${approvecount} bookings?`
                )
            ) { return }
        } else {
            if (
                !window.confirm(
                    `Are you sure you want to approve booking ${rowData[0].shipmentNumber}?`
            )
            ) { return }            
        }

        this.setState({ 
            bookingsToApprove: rowData,
        }, function() {
            // only need to show modal if org subdomain contains certain text
            if (this.state.orgViewCode === 901) {
                this.setState({ 
                    approveBookingModalOpen: true 
                })
            } else {
                this.handleApproveBookingConfirm()
            }
        })

    }

    async editCopyBooking(rowData) {
        const { clientId } = this.state

        const hasPermission = await clientUserHasPermission(clientId, 'editcopybookings')
        if (hasPermission === true)
        {
            if (rowData.length > 1) {
                alert('You can only edit/copy a single booking.')
                return
            }

            // need to set order number to local storage
            localStorage.setItem('editCopyBookingNumber', rowData[0].shipmentNumber)
            localStorage.setItem('editCopyBookingClientId', clientId)
            
            // redirect to booking page using javascript
            window.location.href = '/shipments/create'
        } 
        else 
        {
            alert('You do not have permission to edit/copy bookings.')
        }
    }

    async rejectBookings(rowData) {

        let rejectCount = rowData.length
        // prompt user to confirm they want to reject booking
        if (rejectCount > 1) {
            if (
                !window.confirm(
                    `Are you sure you want to reject ${rejectCount} bookings?`
                )
            ) { return }
        } else {
            if (
                !window.confirm(
                    `Are you sure you want to reject booking ${rowData[0].shipmentNumber}?`
            )
            ) { return }            
        }

        // check if any of the bookings are already rejected
        let alreadyRejected = false
        rowData.forEach(function(booking) {
            if (booking.bookingRejected) {
                alreadyRejected = true
            }
        })
        if (alreadyRejected) {
            alert('One or more of the selected bookings are already rejected.')
            return
        }

        this.setState({ 
            bookingsToReject: rowData,
        }, function() {
            this.handleRejectBookingConfirm()
        })

    }

    async toggleShipmentFlagged(rowData) {
        this.setState({ searching: true })
        const token = await authService.getAccessToken()
        const rawResponse = await fetch('api/shipments/FlagShipment', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ClientId: this.state.clientId,
                FlaggedValue: rowData.shipmentFlagged ? false : true,
                ShipmentNumber: rowData.shipmentNumber
            })
        })

        if (rawResponse.ok) {
            // indicate success to user
            this.populateTableData()
        } else {
            var responseText = await rawResponse.text()
            console.log(responseText)
            this.setState({ searching: false })
            if (rawResponse.status === 403) {
                // feature not enabled
                alert(responseText)
            } else {
                alert('Failed to flag shipment, please contact support.')
            }
        }
    }

    async populateTableData(useQuickSearch = true, initialLoad = false) {

        // set summary tiles back to default
        this.setState({ currentSummaryTile: 1 })
        
        const { clientId, key, orderClients } = this.state
        const orderClient = orderClients?.find(c => c.id === clientId)

        this.setState({ showOrders: orderClient ? true : false })
        this.setState({ searching: true })
        
        const token = await authService.getAccessToken()

        if (clientId !== null) {
            this.setState({ error: false })

            const headers = {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }

            const response = await fetch(
                `api/shipments/GetShipments?` +
                    this.getQueryString(useQuickSearch, initialLoad),
                headers
            )

            if (response.ok) {
                const data = await response.json()

                this.setState({
                    resultsBox1Value: data.resultsBox1Value,
                    resultsBox1Title: data.resultsBox1Title,
                    resultsBox2Value: data.resultsBox2Value,
                    resultsBox2Title: data.resultsBox2Title,
                    resultsBox3Value: data.resultsBox3Value,
                    resultsBox3Title: data.resultsBox3Title,
                    resultsBox4Value: data.resultsBox4Value,
                    resultsBox4Title: data.resultsBox4Title,
                    resultsBox5Value: data.resultsBox5Value,
                    resultsBox5Title: data.resultsBox5Title,
                    resultsBox6Value: data.resultsBox6Value,
                    resultsBox6Title: data.resultsBox6Title
                })

                localforage.setItem(key + '.shipmentList', data.shipments)
                localforage.setItem(key + '.items', data.shipments)
                localStorage.setItem(
                    key + '.resultsBox1Value',
                    data.resultsBox1Value
                )
                localStorage.setItem(
                    key + '.resultsBox1Title',
                    data.resultsBox1Title
                )
                localStorage.setItem(
                    key + '.resultsBox2Value',
                    data.resultsBox2Value
                )
                localStorage.setItem(
                    key + '.resultsBox2Title',
                    data.resultsBox2Title
                )
                localStorage.setItem(
                    key + '.resultsBox3Value',
                    data.resultsBox3Value
                )
                localStorage.setItem(
                    key + '.resultsBox3Title',
                    data.resultsBox3Title
                )
                localStorage.setItem(
                    key + '.resultsBox4Value',
                    data.resultsBox4Value
                )
                localStorage.setItem(
                    key + '.resultsBox4Title',
                    data.resultsBox4Title
                )
                if (data.resultsBox5Title) {
                    localStorage.setItem(
                        key + '.resultsBox5Value',
                        data.resultsBox5Value
                    )
                    localStorage.setItem(
                        key + '.resultsBox5Title',
                        data.resultsBox5Title
                    )
                }
                else {
                    localStorage.removeItem(key + '.resultsBox5Value')
                    localStorage.removeItem(key + '.resultsBox5Title')
                }

                if (data.resultsBox6Title) {
                    localStorage.setItem(
                        key + '.resultsBox6Value',
                        data.resultsBox6Value
                    )
                    localStorage.setItem(
                        key + '.resultsBox6Title',
                        data.resultsBox6Title
                    )
                }
                else {
                    localStorage.removeItem(key + '.resultsBox6Value')
                    localStorage.removeItem(key + '.resultsBox6Title')
                }

                this.setState({
                    items: data.shipments,
                    shipmentList: data.shipments,
                    loading: false
                })
            } else {
                this.setState({ loading: false, error: true })
            }
        } else {
            this.setState({ loading: false })
        }

        this.setState({ searching: false })
    }
}
export default withStyles(styles)(ShipmentsTable)
