import React, { useState, useEffect } from 'react'
import authService from '../api-authorization/AuthorizeService'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, CardContent, Select, FormControl } from '@material-ui/core'
import { Table, TableContainer, TableBody } from '@material-ui/core'
import { TableRow, TableCell, TableHead, InputLabel } from '@material-ui/core'
import {
    TextField,
    IconButton,
    Button,
    Input,
    MenuItem
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import Typography from '@material-ui/core/Typography'
import AlertMessage from '../shared/Alert'
import _ from 'lodash'
import { getDataMapping, setDataMapping } from '../../utils/data-mapping'
import { getCreatedShipment } from '../../api/api'
import { ThemeProvider, createTheme } from '@mui/material'
import MaterialTable from 'material-table'
import { retrieveView } from '../shared/Views'

/*const CreateShipment = {
    pathname: `${ApplicationPaths.CreateShipment}`
}*/

const classes = makeStyles(theme => ({
    table: {
        minWidth: 650
    }
}))

const emptyRow = {
    foo: ''
}

export default function CreateShipmentTable(props) {
    const [shipment, setShipment] = useState([])
    const [key, setKey] = useState(['shipment.create'])
    const [commodities, setCommodities] = useState([])
    const [weightUnits, setWeightUnits] = useState([])
    const [lengthUnits, setLengthUnits] = useState([])
    const [pieceUnits, setPieceUnits] = useState([])
    const [noteUnits, setNoteUnits] = useState([])
    const [containerModes, setContainerModes] = useState([])
    const [orderLineColumnHeaders, setOrderLineColumnHeaders] = useState([])
    const [volumeUnits, setVolumeUnits] = useState([])

    const state = {
        commodities,
        weightUnits,
        volumeUnits,
        lengthUnits,
        noteUnits,
        containerModes,
        pieceUnits
    }

    const [rows, setRows] = useState([])
    const [alert, setAlert] = useState([
        {
            open: false,
            success: false,
            message: ''
        }
    ])

    useEffect(() => {
        const shipmentNumber = sessionStorage.getItem(shipmentIdKey())

        if (shipmentNumber !== null && shipmentNumber !== undefined) {
            getTypesData(shipmentNumber)
            getCreatedShipment(shipmentNumber, null, null, data => {
                setShipment(data)
                // if notes then we need to add new line characters where '##newline##' is found
                if (props.shipmentKey === 'notes') {
                    const notes = data[props.shipmentKey].map(note => {
                        return {
                            noteTypeCode: note.noteTypeCode,
                            noteText: note.noteText.replace(/##newline##/g, '\n')
                        }
                    })
                    setRows(notes)
                } else {
                    setRows(data[props.shipmentKey])
                }
            })
        } else {
            window.location.replace('/shipments/create')
        }
    }, [])

    useEffect(() => {
        setLinkedOrderLineColumnDefinitions()
    }, [])

    const setLinkedOrderLineColumnDefinitions = async () => {
        const data = await retrieveView(null, 'CreateBookingLinkedOrderLines')
        if (!data) {
            setOrderLineColumnHeaders([])
        } else {
            const columnDefinitions = data?.columnDefinitionsArray
            let parsedColumns = columnDefinitions?.filter(e => e.visible === true)
            // need to map field and property to label and key
            parsedColumns = parsedColumns?.map(e => {
                return {
                    title: e.title,
                    field: e.value
                }       
            })
            setOrderLineColumnHeaders(parsedColumns)
        }
    }

    const updateShipmentField = values => {
        setShipment({ ...shipment, ...values })
    }

    const shipmentIdKey = () => {
        return key + '.shipmentId'
    }

    const handleCloseAlert = () => {
        setAlert({
            open: false,
            success: false,
            message: ''
        })
    }

    const handleChange = (e, idx, name) => {
        const { value } = e.target
        updateValueInRow(idx, name, value)
    }

    const updateValueInRow = (idx, key, value) => {
        setRows(
            rows.map((item, index) =>
                index === idx ? { ...item, [key]: value } : item
            )
        )
    }

    const handleAddRow = () => {
        if ( rows.length === noteUnits.length ) {
            setAlert({
                open: true,
                success: false,
                message: 'You have reached the maximum number of notes.'
            })
            
        } else {
            setRows([...rows, emptyRow])
        }
        
    }

    const setDataTypes = data => {
        const values1 = data.filter(item => item.propertyType === 'COMMODITY')
        if (values1.length > 0) {
            setCommodities(values1.filter(item => item.isAvailable === true))
        }

        const values2 = data.filter(item => item.propertyType === 'WEIGHT_UOM')
        if (values2.length > 0) {
            setWeightUnits(values2.filter(item => item.isAvailable === true))
        }

        const values3 = data.filter(item => item.propertyType === 'LENGTH_UOM')
        if (values3.length > 0) {
            setLengthUnits(values3.filter(item => item.isAvailable === true))
        }

        const values4 = data.filter(
            item => item.propertyType === 'CONTAINER_MODE'
        )
        if (values4.length > 0) {
            setContainerModes(values4.filter(item => item.isAvailable === true))
        }

        const values5 = data.filter(item => item.propertyType === 'NOTE_TYPE')
        if (values5.length > 0) {
            setNoteUnits(values5.filter(item => item.isAvailable === true))
        }

        const values6 = data.filter(item => item.propertyType === 'PIECES_UOM')
        if (values6.length > 0) {
            setPieceUnits(values6.filter(item => item.isAvailable === true))
        }

        const values7 = data.filter(item => item.propertyType === 'VOLUME_UOM')
        if (values7.length > 0) {
            setVolumeUnits(values7.filter(item => item.isAvailable === true))
        }
    }

    const handleRemoveSpecificRow = rowIndex => () => {
        // remove specified row from rows array
        const updatedRows = [...rows]
        updatedRows.splice(rowIndex, 1)
        setRows(updatedRows)
    }

    const renderInputCell = (index, key) => {
        return (
            <TableCell>
                {key === 'noteText' ? (
                    <pre>
                        <textarea
                            id={key}
                            value={rows[index][key] || ''}
                            onChange={e => handleChange(e, index, key)}
                            className="form-control"
                            rows={2}
                        />
                    </pre>
                ) : (
                    <TextField
                        id={key}
                        value={rows[index][key] || ''}
                        onChange={e => handleChange(e, index, key)}
                        className="form-control"
                    />
                )}
            </TableCell>
        )
    }

    const renderSelectCell = ( 
        idx,
        key,
        label,
        data,
        unique = false,
        keyValue = 'propertyKey',
        valueKey = 'value'
    ) => {
        // if unique is true, then we need to filter out the rows that have already been selected
        if (unique) {
            const previousRows = rows.slice(0, idx)
            const uniqueValues = state[data].filter(
                item =>
                    !previousRows.some(
                        row => row[key] === item[keyValue]
                    )
            )
            // update state
            state[data] = uniqueValues
        }

        return (
            <TableCell>
                <FormControl style={{ width: '100%' }}>
                    <Select
                        name={key}
                        label={label}
                        placeholder={label}
                        onChange={e => {
                            updateValueInRow(idx, key, e.target.value)
                        }}
                        value={rows[idx][key]}
                        input={<Input id={key}/>}>
                        {state[data].map(option => (
                            <MenuItem 
                                key={option[keyValue]}
                                value={option[keyValue]}>
                                {key !== 'noteType'
                                    ? `${option[keyValue]} - ${option[valueKey]}`
                                    : option[valueKey]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </TableCell>
        )
    }

    const addPackLineFromLinkedOrderLines = (rowData) => {
    
        // need to get unique order numbers from rowData array
        const uniqueOrderNumbers = rowData.reduce((acc, curr) => {
            if (!acc.includes(curr.orderNumber)) {
                acc.push(curr.orderNumber)
            }
            return acc
        }, [])

        const orderNumbers = uniqueOrderNumbers.join(', ')
        const summedVolume = rowData.reduce((a, b) => a + (b['volume'] || 0), 0)
        const summedWeight = rowData.reduce((a, b) => a + (b['weight'] || 0), 0)
        const summedQuantity = rowData.reduce((a, b) => a + (b['outerPacks'] || 0), 0)
        const newRow = {
            referenceNumber: orderNumbers,
            volume: summedVolume.toFixed(2),
            volumeUOM: rowData[0].volumeUOM,
            weight: summedWeight.toFixed(2),
            weightUOM: rowData[0].weightUOM,
            quantity: summedQuantity,
            quantityUOM: rowData[0].outerPacksType,
        }

        setRows([...rows, newRow])
    }


    const renderLinkedOrderLines = () => {
        const tableTheme = createTheme()
        const tableActions = [
            {
                icon: () => {
                    return (
                        <Button variant='contained' color='primary'>
                            <AddCircleIcon/> Create Pack Line
                        </Button>
                    )
                },
                onClick: (event, rowData) => {
                    addPackLineFromLinkedOrderLines(rowData)
                },
                tooltip: 'Create Pack Line'
            }
        ]
        return (
            <>
                <div>
                    <Grid
                        container
                        spacing={0}>
                        <Grid item xs={12}>
                            <ThemeProvider theme={tableTheme}>
                                <MaterialTable
                                    title='Linked Order Lines'
                                    columns={orderLineColumnHeaders}
                                    data={shipment.linkedOrderLines}
                                    actions={tableActions}
                                    options={{
                                        filtering: false,
                                        pageSizeOptions: [25, 50, 75],
                                        sorting: true,
                                        pageSize: 25,
                                        maxBodyHeight: '200px',
                                        showTitle: true,
                                        search: false,
                                        columnsButton: false,
                                        doubleHorizontalScroll: true,
                                        draggable: false,
                                        showFirstLastPageButtons: true,
                                        toolbar: true,
                                        padding: 'dense',
                                        selection: true,
                                        pagination: {
                                            rowsPerPage: 'Rows per page:',
                                            displayRows: 'off'
                                        },
                                    }}
                                />
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                </div>  
                <div className="separator"></div>
                <div className="spacer mb-40px w-100"></div>  
            </>
        )
    }

    const getTypesData = async (createShipmentId) => {
        const token = await authService.getAccessToken()
        const response = await fetch(
            'api/DataManagement/GetDataTypes?dataTypesRequested=ALL&createShipmentId=' + createShipmentId,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` }
            }
        )
        if (response.ok) {
            const data = await response.json()
            setDataMapping(JSON.stringify(data))
            setDataTypes(data)
        }
    }

    const renderAlert = () => {
        return alert.open ? (
            <div className="row mb-4">
                <AlertMessage
                    open={alert.open}
                    success={alert.success}
                    message={alert.message}
                    onClose={() => handleCloseAlert()}
                />
            </div>
        ) : null
    }

    const handleSubmit = e => {
        e.preventDefault()
        
        // check if shipment type if FCL and if so, make sure that there is at least one container loaded
        if (props.shipmentKey === 'containers' && shipment.containerTypeCode === 'FCL' && rows.length === 0) {
            setAlert({
                open: true,
                success: false,
                message: 'FCL shipments require at least one container.'
            })
            return
        }
       
        // check if each container row has information filled in
        if (props.shipmentKey === 'containers') {
            const emptyFields = rows.flatMap(row => props.columns
                .filter(column => column.required && !row[column.id])
                .map(column => column.label)
            )
            if (emptyFields.length > 0) {
                setAlert({
                    open: true,
                    success: false,
                    message: 'Container Type and Number of Containers are required fields.'
                })
                return
            }
        }

        // check any row in rows has no type code
        if (props.shipmentKey === 'notes' && rows.some(row => !row["noteTypeCode"])) {
            setAlert({
                open: true,
                success: false,
                message: 'Note Type Code is required.'
            })
            return
        }

        saveShipmentData(_.omitBy(shipment, _.isNil))
    }

    const parseRowData = () => {
        const floats = props.columns
            .filter(column => column.type === 'number')
            .map(column => column.id)
        return rows.map(row => {
            for (var key in row) {
                const keyIn = floats.includes(key)
                row[key] = keyIn ? parseFloat(row[key]) : row[key]
            }
            return row
        })
    }

    const saveShipmentData = async data => {
        
        if (props.shipmentKey === 'notes') {
            const notes = rows.map(row => {
                return {
                    noteTypeCode: row.noteTypeCode,
                    noteText: row.noteText.replace(/\n/g, '##newline##')
                }   
            })
            setRows(notes)
        }

        data[props.shipmentKey] = parseRowData()

        const token = await authService.getAccessToken()
        await fetch('api/createShipments/PutCreateShipment?sourceForm=' + props.sourceForm, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                setAlert({
                    open: true,
                    success: true,
                    message: 'Shipment information is saved. Redirecting...'
                })
                setTimeout(() => {
                    window.location.replace(props.redirectURL)
                }, 3000)
            } else {
                setAlert({
                    open: true,
                    success: false,
                    message: 'Sorry, there was an error while saving shipment details.'
                })
            }
        })
    }

    const renderItem = (item, rowIndex) => {
        if (item.type === 'text' || item.type === 'number') {
            return renderInputCell(rowIndex, item.id)
        } else if (item.type === 'dropdown') {
            return renderSelectCell(rowIndex, item.id, item.label, item.dataKey, item.unique)
        }
        return <div />
    }

    return (
        <div>
            <CardContent>
                <form
                    className={classes.root}
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete="off">
                    <Grid container direction="column">
                        <Grid item>
                            <div>
                                <div className="row">
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography
                                                gutterBottom
                                                variant="h6">
                                                {props.title || ''}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell key={'line'}>
                                                    Line # 
                                                </TableCell>
                                                {props.columns.map(
                                                    (column, index) => (
                                                        <TableCell
                                                            key={index}
                                                            align={column.align}
                                                            style={{
                                                                minWidth:
                                                                    column.minWidth
                                                            }}>
                                                            {column.label}
                                                        </TableCell>
                                                    )
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows ? rows.map((item, idx) => (
                                                <TableRow key={idx}>
                                                    <TableCell>
                                                        {idx + 1}
                                                    </TableCell>
                                                    {props.columns.map(function(
                                                        i
                                                    ) {
                                                        return renderItem(
                                                            i,
                                                            idx
                                                        )
                                                    })}

                                                    <TableCell>
                                                        <IconButton
                                                            color="default"
                                                            onClick={handleRemoveSpecificRow(
                                                                idx
                                                            )}
                                                            component="span">
                                                            <RemoveCircleIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )) : null}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <IconButton
                                    color="default"
                                    onClick={handleAddRow}
                                    component="span">
                                    <AddCircleIcon />
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <div className="w-100 mb-4 d-flex justify-content-end">
                                    {renderAlert()}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className="w-100 mb-4 d-flex justify-content-end">
                                    {props.backURL !== null &&
                                        props.backURL !== undefined && (
                                            <Button
                                                className={`mr-4 ${classes.submit}`}
                                                onClick={() =>
                                                    window.location.replace(
                                                        props.backURL
                                                    )
                                                }
                                                variant="contained"
                                                color="primary">
                                                Back
                                            </Button>
                                        )}
                                    {props.showSkip && (
                                        <Button
                                            className={`mr-4 ${classes.submit}`}
                                            onClick={() =>
                                                // check if container type is 'FCL', if so then make sure at least one container has been added
                                                props.shipmentKey === 'containers' &&
                                                shipment.containerTypeCode === 'FCL' &&
                                                rows.length === 0
                                                    ? setAlert({
                                                            open: true,
                                                            success: false,
                                                            message:
                                                                'FCL shipments require at least one container.'
                                                        })
                                                    :
                                                    window.location.replace(
                                                        props.redirectURL
                                                    )
                                            }
                                            variant="contained"
                                            color="primary">
                                            Skip
                                        </Button>
                                    )}
                                    <Button
                                        type="submit"
                                        className={classes.submit}
                                        variant="contained"
                                        color="primary">
                                        {props.submitButtonText || 'Save/Next'}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                {
                    props.shipmentKey === 'packLines' &&
                    renderLinkedOrderLines()
                }
            </CardContent>
        </div>
    )
}
