import React, { useEffect, useState } from 'react'
import ShipmentCreationProgress from './ShipmentCreationProgress'
import authService from '../api-authorization/AuthorizeService'
import {
    getShipmentKey,
    removeShipmentKey
} from '../../utils/shipment'
import { getCreatedShipment, getClientData } from '../../api/api'
import { Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AlertMessage from '../shared/Alert'
import _ from 'lodash'
import '../shared/ShipmentDetailCard'
import ShipmentInfoCard from '../shared/ShipmentInfoCard'
import ShipmentDetailCard from '../shared/ShipmentDetailCard'
import ShipmentNotesTable from '../shared/ShipmentNotesTable'
import ShipmentContainersTable from '../shared/ShipmentContainersTable'
import ShipmentPacklinesTable from '../shared/ShipmentPacklinesTable'
import ShipmentContactCard from '../shared/ShipmentContactCard'
import DateFnsUtils from '@date-io/moment'
import { isNotFclOrFtl } from '../../utils/shipment'
import DialogOk from '../shared/DialogOk'
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants'
import { useHistory } from 'react-router-dom'
import { getPreviousEnabledBookingStep } from './CreateBookingFunctions'
import { getOrgViewCodeFromOrganization } from '../../utils/organizations'
import { retrievePageFields } from '../shared/Views'

const classes = makeStyles(theme => ({}))

export default function Review() {
    const [shipment, setShipment] = useState(null)
    const [showContainers, setShowContainers] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [submitSuccess, setSubmitSuccess] = useState(false)
    const [createdShipment, setCreatedShipment] = useState(null)
    const [dialogMessage, setDialogMessage] = useState('')
    const [alert, setAlert] = useState([
        {
            open: false,
            success: false,
            message: ''
        }
    ])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [organization, setOrganization] = useState(null)
    const [client, setClient] = useState(null)
    const [previousBookingStep, setPreviousBookingStep] = useState(null)
    const [pageFields, setPageFields] = useState(null)
    const [orgViewCode, setOrgViewCode] = useState(null)

    const dateFns = new DateFnsUtils()
    const history = useHistory();
    const getFormattedDate = (date, organization) => {
        if (date === null || date === undefined || date === '0001-01-01T00:00:00' || organization === null || organization === undefined) {
            return '-'
        }
        let dateFormat = organization?.pickerDateFormat
        return dateFns.moment(date).format(dateFormat)
    }

    useEffect(() => {
        retrievePageFields('createbooking').then(data => {
            setPageFields(data)
        })
        getOrgViewCode()
    }, [])

    useEffect(() => {
        const shipmentNumber = getShipmentKey()

        fetch('api' + ApplicationPaths.AppInfo).then(response => {
            response.json().then(data => {
                setOrganization(data)
            })
        })

        if (shipmentNumber !== null && shipmentNumber !== undefined) {
            getCreatedShipment(shipmentNumber, null, null, data => {
                setShipment(data)
                setShowContainers(!isNotFclOrFtl(data.containerTypeCode))
            })
        } else {
            window.location.replace('/shipments/create')
        }
    }, [])

    useEffect(() => {
        if (!shipment) return
        getClientData(
            shipment.clientId,
            data => {
                setClient(data)
                setPreviousBookingStep(getPreviousEnabledBookingStep(4, data.bookingSteps, shipment.containerTypeCode))
            },
            () => {
                setClient({})
            }
        )
    }, [shipment])

    const getOrgViewCode = async () => {
        const viewCode = await getOrgViewCodeFromOrganization()
        setOrgViewCode(viewCode)
    }

    const saveShipmentData = async (data, submitType) => {

        // need to check if shipment is FCL, if so it needs to have at least one container
        if (data.containerTypeCode) {
            if (data.containerTypeCode === 'FCL') {
                if (!data.containers || data.containers.length === 0) {
                    setAlert({
                        open: true,
                        success: false,
                        message: 'FCL shipments require at least one container.'
                    })
                    return
                }
            }
        }

        // need to check if summed quantity from packlines is equal to piecesCount, if there are packlines
        if (data.packLines && data.packLines.length > 0) {
            const summedQuantity = data.packLines.reduce((acc, packline) => {
                return acc + packline.quantity
            }, 0)
            if (summedQuantity !== data.piecesCount) {
                // get the user to confirm they still want to continue
                if (
                    !window.confirm(
                        `The total quantity of packlines (${summedQuantity}) does not match the total pieces count (${data.piecesCount}). \nDo you still want to continue?`
                    )
                ) { return }
            }
        }

        if (submitType === 'update') {
            if (
                !window.confirm(
                    `Submit booking details? \nThis will update booking # ${data.cW1BookingNumber} in CW1.`
                )
            ) { return }
        } else if (submitType === 'new') {
            if (
                !window.confirm(
                    `Submit booking details? \nThis will create a new booking in CW1.`
                )
            ) { return }
        }

        if (submitType == 'update') {
            setIsUpdating(true)
        } else if (submitType === 'new') {
            setIsSubmitting(true)
        }

        if (submitType === 'new') {
            data.cW1BookingNumber = null
        }
        const token = await authService.getAccessToken()
        await fetch('api/createShipments/PutCreateShipment?shouldSubmit=true', {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(async response => {
                if (response.ok) {
                    const newData = await response.json()

                    if (newData.shipmentCreated === true) {
                        setDialogOpen(true)
                        setSubmitSuccess(true)
                        setCreatedShipment(newData)
                        setDialogMessage(
                            'Booking was successful, your shipment booking reference is ' +
                            newData.shipmentNumber +
                            ' from ' +
                            newData.organizationName +
                            ' System – once our team confirms the booking ' +
                            newData.shipmentNumber +
                            ' will appear in your active shipment list.'
                        )
                        removeShipmentKey()
                    } else {
                        setDialogOpen(true)
                        setSubmitSuccess(false)
                        setDialogMessage(
                            'There was an error creating this booking: ' +
                                newData.errorMessage || ''
                        )
                    }
                } else {
                    setAlert({
                        open: true,
                        success: false,
                        message:
                            'Sorry, there was an error while saving this details.'
                    })
                }
            })
            .catch(error => {
                setAlert({
                    open: true,
                    success: false,
                    message:
                        'Sorry, there was an error while saving this Shipment information.'
                })
                setIsSubmitting(false)
                setIsUpdating(false)
            })
        // probably don't need this
        setIsSubmitting(false)
        setIsUpdating(false)
    }

    const handleCloseAlert = () => {
        setAlert({
            open: false,
            success: false,
            message: ''
        })
    }

    const goToSection = (path) => {
        history.push(path)
    }

    const renderAlert = () => {
        return alert.open ? (
            <div className="row mb-4">
                <AlertMessage
                    open={alert.open}
                    success={alert.success}
                    message={alert.message}
                    onClose={() => handleCloseAlert()}
                />
            </div>
        ) : null
    }

    const getShipperName = () => {
        if (
            shipment !== null &&
            shipment.contacts !== null &&
            shipment.contacts !== undefined
        ) {
            const contacts = shipment.contacts.filter(
                contact => contact.addressType === 'PIC'
            )
            if (contacts.length > 0) {
                return contacts[0].name
            }
        }

        return '-'
    }

    const getConsigeeName = () => {
        if (
            shipment !== null &&
            shipment.contacts !== null &&
            shipment.contacts !== undefined
        ) {
            const contacts = shipment.contacts.filter(
                contact => contact.addressType === 'DLV'
            )
            if (contacts.length > 0) {
                return contacts[0].name
            }
        }

        return '-'
    }

    const handleActionDialog = action => {
        setDialogOpen(false)
        if (submitSuccess) {
            goToSection(ApplicationPaths.Bookings + '/' + createdShipment.clientId + '/' + createdShipment.shipmentNumber)
        }
    }

    const parseShipmentDetailData = data => {
        // need to remove orderreferencenumber property from data object
        // since it is not part of the shipment object
        const { orderReferenceNumber, ...shipment } = data
        return shipment
    }

    const getCargoReadyDate = (shipment) => {
        switch (orgViewCode) {
            case 931:
                return getFormattedDate(shipment.requiredPickup, organization)
            default:
                return getFormattedDate(shipment.cargoReadyDate, organization)
        }
    }

    const showField = (field) => {
        if (pageFields) {
            const requiredField = pageFields.find(pageField => pageField.field === field)
            if (requiredField) {
                return requiredField.visible
            }
            return true
        }
        return true
    }

    const getShowRequiredETD = () => {
        return showField('review.etd')
    }

    const getShowRequiredETA = () => {
        return showField('review.eta')
    }

    const getShowRequiredPickup = () => {
        return showField('review.requiredpickup')
    }

    const getShowRequestedDelivery = () => {
        return showField('review.requesteddelivery')
    }

    const getShowShippersRef = () => {
        return showField('review.shippersref')
    }

    const getShowOwnersRef = () => {
        return showField('review.ownersref')
    }

    return (
        <div>
            {client && (
                <div className="mb-4 w-100">
                    <ShipmentCreationProgress
                        client={client}
                        index={4}
                        hideContainers={!showContainers}
                    />
                    <Grid container spacing={2} className="mb-4">
                        <Grid item xs={4}>
                            <ShipmentDetailCard
                                item={parseShipmentDetailData(shipment)}
                                itemextra={{quantity: shipment.piecesCount + ' ' + shipment.piecesUOM}}
                                shippersRef={shipment.clientReferenceNumber}
                                ownerRef={shipment.ownerRef}
                                orderRef={shipment.orderReferenceNumber}
                                volumeLabel="Volume"
                                volume={shipment.volume + ' ' + shipment.volumeUOM}
                                weight={shipment.weight + ' ' + shipment.weightUOM}
                                shippersRefLabel="Client's Ref #"
                                organization={organization}
                                showShippersRef={getShowShippersRef()}
                                showOwnersRef={getShowOwnersRef()}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShipmentInfoCard
                                item={shipment}
                                itemextra={null}
                                showFirstRow={false}
                                etd={getFormattedDate(shipment.requiredETD, organization)}
                                eta={getFormattedDate(shipment.requiredETA, organization)}
                                atd={'-'}
                                ata={'-'}
                                shipper={getShipperName()}
                                consignee={getConsigeeName()}
                                origin={shipment.originUNLOCOCode}
                                destination={shipment.destinationUNLOCOCode}
                                requestedDelivery={getFormattedDate(shipment.requestedDelivery, organization)}
                                requiredPickup={getFormattedDate(shipment.requiredPickup, organization)}
                                showRequestedDelivery={getShowRequestedDelivery()}
                                showRequiredPickup={getShowRequiredPickup()}
                                showRequiredETD={getShowRequiredETD()}
                                showRequiredETA={getShowRequiredETA()}
                                cargoReadyDate={getCargoReadyDate(shipment)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShipmentNotesTable
                                item={shipment}
                                isReview={true}
                                classes={classes}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className="mb-4">
                        <Grid item xs={4}>
                            {shipment !== null &&
                                shipment !== undefined &&
                                shipment.contacts !== null &&
                                shipment.contacts !== undefined && (
                                    <ShipmentContactCard
                                        title="Shipper"
                                        item={
                                            shipment.contacts.filter(
                                                contact =>
                                                    contact.addressType === 'PIC'
                                            )[0]
                                        }
                                    />
                                )}
                        </Grid>
                        <Grid item xs={4}>
                            {shipment !== null &&
                                shipment !== undefined &&
                                shipment.contacts !== null &&
                                shipment.contacts !== undefined && (
                                    <ShipmentContactCard
                                        title="Consignee"
                                        item={
                                            shipment.contacts.filter(
                                                contact =>
                                                    contact.addressType === 'DLV'
                                            )[0]
                                        }
                                    />
                                )}
                        </Grid>
                        <Grid item xs={4} />
                    </Grid>

                    <Grid container className="mb-4">
                        <ShipmentPacklinesTable
                            item={shipment}
                            organization={organization}
                        />
                    </Grid>

                    {showContainers && (
                        <Grid container className="mb-4">
                            <ShipmentContainersTable
                                item={shipment}
                                isReview={true}
                                organization={organization}
                            />
                        </Grid>
                    )}
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <div className="w-100 mb-4 d-flex justify-content-end">
                                {renderAlert()}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="w-100 mb-4 d-flex justify-content-end">
                                <Button
                                    className={`mr-4 ${classes.submit}`}
                                    onClick={() => window.location.replace(previousBookingStep.href)}
                                    variant="contained"
                                    color="primary">
                                    Back
                                </Button>
                                {(shipment.cW1BookingNumber && (
                                    <>
                                        <Button
                                            type="submit"
                                            onClick={() => {
                                                saveShipmentData(
                                                    _.omitBy(shipment, _.isNil),
                                                    'update'
                                                )
                                            }}
                                            className={classes.submit}
                                            variant="contained"
                                            disabled={isUpdating || isSubmitting}
                                            color="primary">
                                            {isUpdating ? 'Updating...' : 'Update CW1 Booking'}
                                        </Button>
                                        &nbsp;
                                        <Button
                                            type="submit"
                                            onClick={() => {
                                                saveShipmentData(
                                                    _.omitBy(shipment, _.isNil),
                                                    'new'
                                                )
                                            }}
                                            className={classes.submit}
                                            variant="contained"
                                            disabled={isSubmitting || isUpdating}
                                            color="primary">
                                            {isSubmitting ? 'Submitting...' : 'Submit As New Booking'}
                                        </Button>
                                    </>
                                ))}
                                {(!shipment.cW1BookingNumber && (
                                    <Button
                                        type="submit"
                                        onClick={() => {
                                            saveShipmentData(
                                                _.omitBy(shipment, _.isNil),
                                                'new'
                                            )
                                        }}
                                        className={classes.submit}
                                        variant="contained"
                                        disabled={isSubmitting}
                                        color="primary">
                                        {isSubmitting ? 'Submitting...' : 'Submit Booking'}
                                    </Button>
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                    <DialogOk
                        callback={handleActionDialog}
                        show={dialogOpen}
                        title="Booking submission">
                        <p>{dialogMessage}</p>
                    </DialogOk>
                </div>
            )}
        </div>
    )
}
