import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CardTitle from './CardTitle'
import { Tooltip, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

const classes = makeStyles(theme => ({}))

export default function ShipmentInfoCard({
    item,
    itemextra,
    showFirstRow,
    etd,
    eta,
    atd,
    ata,
    shipper,
    consignee,
    origin,
    destination,
    requestedDelivery = null,
    requiredPickup = null,
    showRequestedDelivery = false,
    showRequiredPickup = false,
    showRequiredETD = false,
    showRequiredETA = false,
    cargoReadyDate = null,
    transactiontype 
}) {
    const theme = useTheme()

    useEffect(() => {}, [])

    return (
        <div className="p-0 pl-3">
            <h5 class="text-Curious-blue my-2 font-14">{transactiontype} Info</h5>
            <Grid
                container
                direction="row"
                spacing={1}
                className={theme.bgColorSection}>
                <Grid item xs={12}>
                    {showFirstRow && (
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={4} className="sm-flex-100">
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 ">
                                    {transactiontype} #
                                </Typography>
                                <p className="mb-0 font-16  font-nutosans-bold text-black">
                                    {(item !== null && item.shipmentNumber) ||
                                        '-'}
                                </p>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 ">
                                    Inco Term
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {(itemextra !== null &&
                                        itemextra.shipmentIncoTerm) ||
                                        '-'}
                                </p>
                            </Grid>
                            <Grid item xs={2} className="sm-flex-100">
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 ">
                                    Service Level
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {(itemextra !== null &&
                                        item.serviceLevel) ||
                                        '-'}
                                </p>
                            </Grid>
                            <Grid item xs={2} className="sm-flex-100">
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue">
                                    Transport Mode
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {(item !== null && item.transportMode) ||
                                        '-'}
                                </p>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue">
                                    Masterbill #
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {(itemextra !== null &&
                                        itemextra.masterBillNumber) ||
                                        '-'}
                                </p>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 font-raleway-medium text-lviv-blue">
                                    Housebill #
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {(item !== null && item.houseBillNumber) ||
                                        '-'}
                                </p>
                            </Grid>
                            <Grid item xs={2} className="sm-flex-100">
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 ">
                                    Packing Mode
                                </Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                    {(itemextra !== null &&
                                        item.packingMode) ||
                                        '-'}
                                </p>
                            </Grid>
                            <Grid item xs={2} className="sm-flex-100">
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <div className="column">
                                    <div>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            className="font-12 font-raleway-medium text-lviv-blue">
                                            Consignee
                                        </Typography>
                                        <p className="mb-0 font-14  font-nutosans-bold text-black">
                                            {(item !== null && consignee) ||
                                                '-'}
                                        </p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue">
                                        Shipper
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(item !== null && shipper) || '-'}
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 "></Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black"></p>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <div className="column">
                                    <div>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            className="font-12 font-raleway-medium text-lviv-blue">
                                            ETD
                                        </Typography>
                                        <p className="mb-0 font-14  font-nutosans-bold text-black">
                                            {(item !== null && etd) || '-'}
                                        </p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue">
                                        ATD
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(item !== null && atd) || '-'}
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 "></Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black"></p>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <div className="column">
                                    <div>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            className="font-12 font-raleway-medium text-lviv-blue">
                                            ETA
                                        </Typography>
                                        <p className="mb-0 font-14  font-nutosans-bold text-black">
                                            {(item !== null && eta) || '-'}
                                        </p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <div className="column">
                                    <div>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            className="font-12 font-raleway-medium text-lviv-blue">
                                            ATA
                                        </Typography>
                                        <p className="mb-0 font-14  font-nutosans-bold text-black">
                                            {(item !== null && ata) || '-'}
                                        </p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 "></Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black"></p>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue">
                                        Origin
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(item !== null && origin) || '-'}
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <div>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 font-raleway-medium text-lviv-blue">
                                        Destination
                                    </Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black">
                                        {(item !== null && destination) || '-'}
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={4} className="sm-flex-100">
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="font-12 "></Typography>
                                <p className="mb-0 font-14  font-nutosans-bold text-black"></p>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container direction="row" spacing={1}>
                        <Grid item lg={4} sm={6} xs={12}>
                            <div className="column">
                                {showRequiredPickup && (
                                    <Grid item xs={6} className="sm-flex-100">
                                        <div>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                className="font-12 font-raleway-medium text-lviv-blue">
                                                Required Pickup
                                            </Typography>
                                            <p className="mb-0 font-14  font-nutosans-bold text-black">
                                                {(item !== null && requiredPickup ? requiredPickup : null) || '-'}
                                            </p>
                                        </div>
                                    </Grid>
                                )}
                                <Grid item xs={6} className="sm-flex-100">
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 "></Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black"></p>
                                </Grid>
                                { showRequiredETA && (
                                    <Grid item xs={6} className="sm-flex-100">
                                        <div className="column">
                                            <div>
                                                <Typography
                                                    variant="h6"
                                                    gutterBottom
                                                    className="font-12 font-raleway-medium text-lviv-blue">
                                                    Required ETA
                                                </Typography>
                                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                                    {(item !== null && eta ? eta : null) || '-'}
                                                </p>
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {cargoReadyDate && (
                                    <>
                                        <Grid item xs={6} className="sm-flex-100">
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                className="font-12 "></Typography>
                                            <p className="mb-0 font-14  font-nutosans-bold text-black"></p>
                                        </Grid>
                                        <Grid item xs={6} className="sm-flex-100">
                                            <div>
                                                <Typography
                                                    variant="h6"
                                                    gutterBottom
                                                    className="font-12 font-raleway-medium text-lviv-blue">
                                                    Cargo Ready Date
                                                </Typography>
                                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                                    {(cargoReadyDate) || '-'}
                                                </p>
                                            </div>
                                        </Grid>
                                    </>
                                )}
                            </div>
                        </Grid>
                        <Grid item lg={4} sm={6} xs={12}>
                            <div className="column">
                                { showRequiredETD && (
                                    <Grid item xs={6} className="sm-flex-100">
                                        <div>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                className="font-12 font-raleway-medium text-lviv-blue">
                                                Required ETD
                                            </Typography>
                                            <p className="mb-0 font-14  font-nutosans-bold text-black">
                                                {(item !== null && etd ? etd : null) || '-'}
                                            </p>
                                        </div>
                                    </Grid>
                                )}
                                <Grid item xs={6} className="sm-flex-100">
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className="font-12 "></Typography>
                                    <p className="mb-0 font-14  font-nutosans-bold text-black"></p>
                                </Grid>
                                { showRequestedDelivery && (
                                    <Grid item xs={6} className="sm-flex-100">
                                        <div className="column">
                                            <div>
                                                <Typography
                                                    variant="h6"
                                                    gutterBottom
                                                    className="font-12 font-raleway-medium text-lviv-blue">
                                                    Required Delivery
                                                </Typography>
                                                <p className="mb-0 font-14  font-nutosans-bold text-black">
                                                    {(item !== null && requestedDelivery ? requestedDelivery : null) || '-'}
                                                </p>
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
